import * as client_hooks from '../../../src/hooks.client.ts';

export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19'),
	() => import('./nodes/20'),
	() => import('./nodes/21'),
	() => import('./nodes/22'),
	() => import('./nodes/23'),
	() => import('./nodes/24'),
	() => import('./nodes/25'),
	() => import('./nodes/26'),
	() => import('./nodes/27'),
	() => import('./nodes/28'),
	() => import('./nodes/29'),
	() => import('./nodes/30'),
	() => import('./nodes/31'),
	() => import('./nodes/32'),
	() => import('./nodes/33'),
	() => import('./nodes/34'),
	() => import('./nodes/35'),
	() => import('./nodes/36'),
	() => import('./nodes/37'),
	() => import('./nodes/38'),
	() => import('./nodes/39'),
	() => import('./nodes/40'),
	() => import('./nodes/41'),
	() => import('./nodes/42')
];

export const server_loads = [0];

export const dictionary = {
		"/(root)": [10],
		"/(campaigns)/automators": [~3],
		"/(migrations)/buy": [~4],
		"/documentation": [11],
		"/documentation/how-to-guides/export-from-obsidian-into-todoist": [12],
		"/documentation/how-to-guides/export-todoist-to-markdown": [13],
		"/documentation/how-to-guides/getting-started-with-obsidian-and-ocr": [15],
		"/documentation/how-to-guides/getting-started-with-obsidian-and-todoist": [~16],
		"/documentation/how-to-guides/getting-started-with-obsidian": [14],
		"/documentation/how-to-guides/import-from-todoist-into-obsidian": [17],
		"/documentation/how-to-guides/link-labels-to-locations": [18],
		"/documentation/how-to-guides/metadata-and-description": [19],
		"/documentation/how-to-guides/print-todoist-tasks": [20],
		"/documentation/how-to-guides/sync-between-obsidian-and-todoist": [21],
		"/documentation/how-to-guides/use-ocr-in-obsidian": [22],
		"/documentation/reference-guides/available-commands/sync-based-on-todoist-project": [23],
		"/documentation/reference-guides/available-commands/two-way-sync-between-todoist-and-obsidian": [24],
		"/documentation/reference-guides/liquid": [25],
		"/(migrations)/features/location-labels": [~5],
		"/integrations/obsidian/connect": [~26],
		"/legal/about": [27,[2]],
		"/legal/privacy": [28,[2]],
		"/legal/terms": [29,[2]],
		"/location-labels": [~30],
		"/(migrations)/posts/import-todoist-tasks-into-obsidian": [~7],
		"/(migrations)/posts/make-obsidian-and-todoist-work-better-together": [~8],
		"/(migrations)/posts/[post]": [6],
		"/pricing": [31],
		"/products/todoist/extension": [32],
		"/reports": [~33],
		"/reports/printable": [~34],
		"/solutions": [35],
		"/solutions/export-todoist-to-markdown": [36],
		"/solutions/location-labels": [37],
		"/solutions/obsidian-plugin-for-todoist": [38],
		"/(migrations)/taskbone-ocr-obsidian-plugin": [9],
		"/users/home": [~39],
		"/users/sign-in": [~40],
		"/users/sign-in/[group]": [~41],
		"/users/templates": [~42]
	};

export const hooks = {
	handleError: client_hooks.handleError || (({ error }) => { console.error(error) }),
};

export { default as root } from '../root.svelte';